import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import SEO from "../components/seo"
import { LiveChatLoaderProvider, Intercom } from "react-live-chat-loader"
import FooterSmall from "../components/footerSmall"

import CustomerLogoEdf from "../images/svgs/customer_logo_edf.svg"
import CustomerLogoGumtree from "../images/svgs/customer_logo_gumtree.svg"
import CustomerLogoKpmg from "../images/svgs/customer_logo_kpmg.svg"
import CustomerLogoMgh from "../images/svgs/customer_logo_mgh.svg"
import CustomerLogoPb from "../images/svgs/customer_logo_pb.svg"
import CustomerLogoStatista from "../images/svgs/customer_logo_statista.svg"

import NickelledLogoO from "../images/svgs/nickelled-logo-o.svg"

export default function RequestAQuotePage() {
  return (
    <>
      <SEO
        title="Request a Quote for your Digital Adoption Platform"
        description="Get a quote for your Digital Adoption Platform from Nickelled. We'll help you get the most out of your software investments."
      />
      <LiveChatLoaderProvider providerKey="ii6t4rrk" provider="intercom">
        <Intercom color="#FF9C43" />
      </LiveChatLoaderProvider>
      <div className="w-full bg-white">
        <div class="max-w-7xl mx-auto p-4">
          <div class="flex flex-row flex-wrap justify-between md:flex-nowrap">
            <Link to="/">
              <NickelledLogoO class="h-8 w-auto sm:h-10"></NickelledLogoO>
            </Link>
          </div>
        </div>
      </div>
      <header
        class="w-full"
        style={{
          background: "linear-gradient(45deg, #F15C59 0%, #E47225 100%)",
        }}
      >
        <div class="relative bg-white">
          <div class="lg:absolute lg:inset-0">
            <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
              <img
                class="h-56 w-full object-cover lg:absolute lg:h-full"
                src="https://images.unsplash.com/photo-1556761175-4b46a572b786?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1567&q=80"
                alt=""
              ></img>
            </div>
          </div>
          <div class="relative py-12 px-4 sm:py-24 sm:px-6 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:px-8 lg:py-">
            <div class="lg:pr-8">
              <div class="mx-auto max-w-md sm:max-w-lg lg:mx-0">
                <h2 class="text-3xl font-bold tracking-tight sm:text-4xl">
                  Request a Quote
                </h2>
                <p class="mt-4 text-lg text-gray-500 sm:mt-3">
                  Tell us about your problem and we'll get back to you quickly
                  with a quote bespoke for your needs.
                </p>
                <form
                  name="QuoteForm"
                  method="POST"
                  action="/thanks-for-your-request/"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  class="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
                >
                  <input
                    type="hidden"
                    name="form-name"
                    value="QuoteForm"
                    aria-hidden="true"
                  ></input>
                  <p class="hidden" aria-hidden="true">
                    <label>
                      Don’t fill this out if you’re human:{" "}
                      <input name="bot-field" />
                    </label>
                  </p>
                  <div>
                    <label
                      for="first-name"
                      class="block text-sm font-medium text-gray-700"
                    >
                      First name
                    </label>
                    <div class="mt-1">
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        autocomplete="given-name"
                        required="true"
                        class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      ></input>
                    </div>
                  </div>
                  <div>
                    <label
                      for="last-name"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Last name
                    </label>
                    <div class="mt-1">
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        required="true"
                        autocomplete="family-name"
                        class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      ></input>
                    </div>
                  </div>
                  <div class="sm:col-span-2">
                    <label
                      for="email"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Email
                    </label>
                    <div class="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autocomplete="email"
                        required="true"
                        class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      ></input>
                    </div>
                  </div>
                  <div class="sm:col-span-2">
                    <label
                      for="job"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Job Title
                    </label>
                    <div class="mt-1">
                      <input
                        type="text"
                        name="job"
                        id="job"
                        autocomplete="job"
                        required="true"
                        class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      ></input>
                    </div>
                  </div>
                  <div class="sm:col-span-2">
                    <div class="flex justify-between">
                      <label
                        for="phone"
                        class="block text-sm font-medium text-gray-700"
                        required="true"
                      >
                        Phone
                      </label>
                    </div>
                    <div class="mt-1">
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        autocomplete="tel"
                        aria-describedby="phone-description"
                        class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      ></input>
                    </div>
                  </div>
                  <div class="sm:col-span-2">
                    <label
                      for="company"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Company
                    </label>
                    <div class="mt-1">
                      <input
                        type="text"
                        name="company"
                        id="company"
                        autocomplete="organization"
                        required="true"
                        class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      ></input>
                    </div>
                  </div>
                  <div>
                    <label
                      for="employees"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Number of Employees
                    </label>
                    <div class="mt-1">
                      <select
                        name="employees"
                        id="employees"
                        autocomplete="employees"
                        required="true"
                        class="rounded-md border-gray-300 shadow-sm"
                      >
                        <option value="1-10">1-10</option>
                        <option value="11-50">11-50</option>
                        <option value="51-100">51-100</option>
                        <option value="101-500">101-500</option>
                        <option value="501-1000">501-1000</option>
                        <option value="1001-5000">1001-5000</option>
                        <option value="5001-10000">5001-10000</option>
                        <option value="10001+">10001+</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <div class="flex justify-between">
                      <label
                        for="applications"
                        class="block text-sm font-medium text-gray-700"
                        required="true"
                      >
                        Number of Applications
                      </label>
                    </div>
                    <div class="mt-1">
                      <select
                        name="applications"
                        id="applications"
                        autocomplete="applications"
                        required="true"
                        class="rounded-md border-gray-300 shadow-sm"
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10+">10+</option>
                      </select>
                    </div>
                  </div>
                  <div class="sm:col-span-2">
                    <div class="flex justify-between">
                      <label
                        for="how-can-we-help"
                        class="block text-sm font-medium text-gray-700"
                      >
                        How can we help you?
                      </label>
                      <span
                        id="how-can-we-help-description"
                        class="text-sm text-gray-500"
                      >
                        Max. 500 characters
                      </span>
                    </div>
                    <div class="mt-1">
                      <textarea
                        id="how-can-we-help"
                        name="how-can-we-help"
                        aria-describedby="how-can-we-help-description"
                        rows="4"
                        class="block w-full rounded-md border-gray-300 shadow-sm focus:oOrange focus:oOrange sm:text-sm"
                      ></textarea>
                    </div>
                  </div>
                  <div class="text-right sm:col-span-2">
                    <button
                      type="submit"
                      class="inline-flex justify-center rounded-md border border-transparent text-white bg-gradient-to-r from-oRed to-oOrange py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Get my quote
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div class="bg-silver relative">
        <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <h2 class="text-3xl font-extrabold tracking-tight text-navy text-center">
            Nickelled Products Are Trusted By The Best
          </h2>
          <div class="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-6 lg:mt-8">
            <div class="col-span-1 flex justify-center py-2 px-4 md:py-4 bg-silver">
              <CustomerLogoEdf alt="EDF"></CustomerLogoEdf>
            </div>
            <div class="col-span-1 flex justify-center py-2 px-4 md:py-4 bg-silver">
              <CustomerLogoGumtree alt="Gumtree"></CustomerLogoGumtree>
            </div>
            <div class="col-span-1 flex justify-center py-2 px-4 md:py-4 bg-silver">
              <CustomerLogoKpmg alt="KPMG"></CustomerLogoKpmg>
            </div>
            <div class="col-span-1 flex justify-center py-2 px-4 md:py-4  bg-silver">
              <CustomerLogoMgh alt="McGraw Hill"></CustomerLogoMgh>
            </div>
            <div class="col-span-1 flex justify-center py-2 px-4 md:py-4  bg-silver">
              <CustomerLogoPb alt="Pitney Bowes"></CustomerLogoPb>
            </div>
            <div class="col-span-1 flex justify-center py-2 px-4 md:py-4  bg-silver">
              <CustomerLogoStatista alt="Statista"></CustomerLogoStatista>
            </div>
          </div>
        </div>
      </div>

      <FooterSmall></FooterSmall>
    </>
  )
}
